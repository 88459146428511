.delivery-app-call{
    border: 1px solid #fff;
    padding: 5px 10px;
    margin-left: 40px;
    text-transform: uppercase;
    transition: 400ms;
    border-radius: 4px;
    &:hover{
       background-color: #fff;
       color: get-color(secondary);
    }
}

.mobile-delivery-app-call{
    border: 1px solid #333;
    padding: 5px 15px;
    text-transform: uppercase;
    transition: 400ms;
    border-radius: 4px;
    color: #333;
    margin-bottom: 20px;
    margin-top: -10px;
}