.lojista-benefits{
    padding-top: 80px;
    padding-bottom: 80px;
    .container{
        position: relative;
    }
}

.lojista-benefits__title{
    text-align: center;
    color: get-color(brown);
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 100px;
    position: relative;
    &::after{
        content: '';
        width: 170px;
        height: 4px;
        background-color: get-color(gold);
        position: absolute;
        top: 120%;
        left: calc( 50% - 85px);
    }
    &::before{
        content: '';
        width: 1px;
        height: 70px;
        border: 2px dashed get-color(gold);
        position: absolute;
        top: 130%;
        left: calc(50% - 0.5px);
    }
}

.lojista-benefits-item{
    text-align: center;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 50px;
    &:last-child{
        margin-bottom: 0;
    }
    @include media (get-bp(desktop)){
        margin-bottom: 120px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 500px;
        &:nth-child(even){
            flex-direction: row-reverse;
            .lojista-benefits-item__text{
                padding-left: 40px;
                padding-right: 70px;
            }
            &::before{
                right: unset;
                left: 25%;
            }
            &::after{
                left: unset;
                right: 25%;
            }
        }
    }

    @include media (get-bp(desktop)){
        &::before{
            content: '';
            width: 1px;
            height: 110px;
            border: 2px dashed get-color(gold);
            position: absolute;
            top: 86%;
            right: 25%;
        }
    
        &::after{
            content: '';
            width: 1px;
            height: 80px;
            border: 2px dashed get-color(gold);
            position: absolute;
            top: 112%;
            left: 25%;
        }

        
    
        &:first-child{
            &::before{
                height: 100px;
            }
        }
    
        &:last-child{
            .loja-benefits-item__line{
                display: none;
            }
    
            &::before, &::after{
                display: none;
            }
        }
    }

    &:nth-child(2){
        @include media (get-bp(desktop)){
            .lojista-benefits-item__image{
                width: 35%;
            }
        }
    }
}

.lojista-benefits-item__image{
    width: 100%;
    margin-bottom: 10px;
    
    @include media (get-bp(desktop)){
        width: 45%;
        margin-bottom: 0;
    }
    img{
        width: 100%;
    }
}

.lojista-benefits-item__text{
    position: relative;
    @include media (get-bp(desktop)){
        width: 50%;
        padding-left: 70px;
    }
}

.lojista-benefits-item__title{
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 20px;
    @include media (get-bp(desktop)){
        width: 90%;
        font-size: 30px;
    }
}

.lojista-benefits-item__subtitle{
    font-size: 20px;
    font-weight: 300;
    @include media (get-bp(desktop)){
        width: 90%;
    }
}

.loja-benefits-item__line{
    display: none;
    width: 50%;
    height: 2px;
    border: 2px dashed get-color(gold);
    position: absolute;
    top: 110%;
    left: 25%;
    @include media (get-bp(desktop)){
        display: block
    }
}

.lojista-benefits__top-line{
    display: none;
    width: 275px;
    height: 2px;
    border: 2px dashed get-color(gold);
    position: absolute;
    left: 49.98%;
    top: -20px;
    @include media (get-bp(desktop)){
        display: block
    }
}

.lojista-benefits__vertical-line{
    display: none;
    width: 2px;
    height: 80px;
    border: 2px dashed get-color(gold);
    position: absolute;
    left: 73%;
    top: -20px;
    @include media (get-bp(desktop)){
        display: block
    }
}