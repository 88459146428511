//posicionamento do menu fixo no topo
.main-header-bundle {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
}

.main-header {
    color: get-color(dark);
    transition: 400ms;
    &.active{
        box-shadow: 0 0.2rem 0.7rem rgba(#000, 0.3);
        background-color: get-color(secondary);
    }
    &.lojista{
        background-color: #FFF1DA;
        .burger-icon-container{
            &.is-active{
                .burger-icon{
                    background-color: transparent;
                }
            }
        }
        .burger-icon{
            background-color: #333;
            &::before {
                background-color: #333;
            }
            &::after {
                background-color: #333;
            }
        }
        .menu-container{
            color: #333;
            align-items: center;
            padding-top: 10px;
            .menu-container__listing{
                align-items: center;
            }
            .menu-item{
                &:last-child{
                text-decoration: none !important;
                    a{
                        border: 1px solid #333;
                        padding: 10px;
                        border-radius: 6px;
                        transition: 400ms;
                        &:hover{
                            background-color: #333;
                            color: #fff;

                        }
                    }
                }
            }
        }
    }

    &.mercadoo{
        background-color: #fff;
        .burger-icon-container{
            &.is-active{
                .burger-icon{
                    background-color: transparent;
                }
            }
        }
        .burger-icon{
            background-color: #333;
            &::before {
                background-color: #333;
            }
            &::after {
                background-color: #333;
            }
        }
        .menu-container{
            color: #333;
            align-items: center;
            padding-top: 10px;
            .menu-container__listing{
                align-items: center;
            }
            .menu-item{
                &:last-child{
                text-decoration: none !important;
                    a{
                        border: 1px solid #333;
                        padding: 10px;
                        border-radius: 6px;
                        transition: 400ms;
                        &:hover{
                            background-color: #333;
                            color: #fff;

                        }
                    }
                }
            }
        }
    }
    h1 {
        margin: 0;
    }
}

//menu desktop
.desktop-menu {
    display: none;
    @media (min-width: 1200px) {
        display: block;
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .main-header__logo-container {
        width: 200px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .main-header__logo {
        width: 100%;
        height: 100%;
        display: inline-block;
        object-fit: contain;
    }

    .header-content{
        display: flex;
        align-items: center;flex: 1;
    }

    .menu-container{
        flex: 1;
        .menu-container__listing{
            justify-content: flex-end;
            align-items: center;
        }

        .menu-item{
            margin-left: 30px;
            border-bottom: 3px solid transparent;
            position: relative;
            text-transform: uppercase;
            font-size: 16px;
            &:hover{
                text-decoration: underline;
                .menu-item__submenu{
                    display: block;
                }
            }
        }
        .menu-item__submenu{
            position: absolute;
            left: 0;
            width: 130px;
            font-size: 14px;
            display: none;
            flex-wrap: wrap;
            z-index: 1;
            top: 60px;
            margin: 0;
            a{
                font-size: 12px;
                background-color: get-color(primary);
                padding: 10px;
                display: block;
                width: 100%;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }

    .burger-icon-container{
        display: block;
        margin-left: 30px;
        &.is-active{
            .burger-icon{
                background-color: transparent;
            }
        }
        .burger-icon {
            background-color: get-color(light);

            &::before {
                background-color: get-color(light);
            }

            &::after {
                background-color: get-color(light);
            }
        }
    }
}

//menu mobile
.mobile-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    @media (min-width: 1200px) {
        display: none;
    }

    .mobile-logo {
        width: 180px;
        margin: 0;
    }

    //controle da cor do burger menu (acesso facil)
    .burger-icon {
        background-color: get-color(light);
        &::before {
            background-color: get-color(light);
        }

        &::after {
            background-color: get-color(light);
        }
    }
}

//espacamento do conteudo pra barra do topo
.main-header-clearance {
    padding-top: var(--main-header-height);
}
