.delivery-faq{
    padding-top: 50px;
    padding-bottom: 100px;
    margin-bottom: 50px;
    background-color: #fff;
}

.delivery-faq__title{
    text-align: center;
    color: get-color(brown);
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 40px;
    span{
        color: red;
    }
}

.delivery-faq__listing{
    @include media (get-bp(desktop)){
        width: 80%;
        margin: auto;
    }

    .accordion{
        background-color: #f3f3f3;
        border: 0;
        margin-bottom: 10px;
    }

    .accordion__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
        font-weight: 300;
        &.is-active{
            .accordion__icon{
                transform: rotate(180deg);
            }
        }
    }

    .accordion__content{
        margin-top: 20px;
    }

    .accordion__icon{
        color: get-color(primary);
        font-size: 22px;
        transition: 400ms;
    }

    .accordion__content{
        font-size: 14px;
        font-weight: 300;
    }

    .tab-header{
        justify-content: center;
        margin-bottom: 20px;
    }

    .tab-header-item{
        background-color: transparent;
        color: #717171;
        font-weight: 600;
        &.is-active{
            background-color: transparent;
            color: get-color(secondary);
        }
        &:hover{
            background-color:transparent;
            text-decoration: underline;
        }
    }

    .tab-content{
        border: 0;
        padding: 0;
    }
}