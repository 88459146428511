.actions__title{
    text-align: center;
    margin-top: -70px;
    margin-bottom: 40px;
    font-weight: 300;
}

.actions-buttons{
    margin-bottom: 50px;
    @include media (get-bp(desktop)){
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.action-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 40px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    margin-bottom: 20px;
    color: get-color(brown);
    font-size: 14px;
    text-align: center;
    line-height: 1.2;
    font-weight: bold;
    transition: 400ms;
    @include media (get-bp(desktop)){
        width: 32%;
    }
    &:hover{
        &:nth-child(1){
            background-color: get-color(brown);
        }
        &:nth-child(2){
            background-color: get-color(gold);
        }
        &:nth-child(3){
            background-color: get-color(primary);
        }
        color: #fff;
    }
}

.action-button__icon{
    font-size: 42px;
    text-align: center;
    margin-right: 20px;
}