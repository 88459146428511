.user-terms-content{
    background: linear-gradient(107deg, rgba(255,102,0,1) 13%, rgba(254,196,74,1) 69%);
    padding-top: 100px;
    padding-bottom: 100px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .container{
        height: 100%;
    }
}

.user-terms-content__box{
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    flex: 1;
}