.mobile-menu-is-open {
    overflow-y: hidden;
}

.main-menu-mobile {
    position: fixed;
    top: var(--main-header-height);
    left: 0;
    bottom: 0;
    right: 0;
    z-index: z(modal);
    padding: 10% 5%;
    overflow: auto;
    transform: translate3d(-100%, 0, 0);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s;
    @include media(get-bp(desktop)) {
        display: none;
    }

    &.mercadoo{
        background-color: #333;
        .menu-item{
            color: #FFF1DA;
            font-weight: 300;
            &.request{
                a{
                    display: inline-flex;
                    background-color: get-color(secondary);
                    padding: 10px 20px;
                    border-radius: 4px;
                    text-transform: uppercase;
                }
            }
        }
        .mercadoo-social-item__text{
            color: #fff;
        }
    }

    .menu-container__listing{
        display: block;
    }

    .menu-item{
        margin-bottom: 20px;
        color: #333;
        font-weight: 300;
        a{
            display: block;
            font-size: 20px;
            margin-bottom: 20px;
        }

        .menu-item__submenu{
            a{
                font-size: 18px;
            }
        }
    }


    &.is-active {
        transform: translate3d(0, 0, 0);
    }
}


.mercadoo-mobile-social{
    margin-top: 30px;
}

.mercadoo-social-item{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.mercadoo-social-item__icon{
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: get-color(secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    margin-right: 10px;
    color: #fff;
}

.mercadoo-social-item__text{
    color: #333;
}