.footer-container{
    background-color: #333333;
    padding-top: 40px;
    padding-bottom: 40px;
    color: get-color(light);
    .container{
        @include media (get-bp(desktop)){
            display: flex;
            justify-content: space-between;
        }
    }
}

.footer-container__logo-column{
    margin-bottom: 50px;
    @include media (get-bp(desktop)){
        width: 500px;
        margin-bottom: 0;
    }
}

.footer-logo{
    filter: brightness(0) invert(1);
    margin-bottom: 20px;
    img{
        width: 200px;
        @include media (get-bp(desktop)){
            width: 250px;
        }
    }
}

.footer-container__copyright{
    font-size: 14px;
    margin-bottom: 20px;
    a{
        color: get-color(primary);
        text-decoration: underline;
    }
}

.footer-container__columns-listing{
    @include media (get-bp(desktop)){
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 50%;
    }
}

.footer-column-item{
    margin-bottom: 30px;
    @include media (get-bp(desktop)){
        width: 250px;
    }
}

.footer-column-item__title{
    color: get-color(primary);
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 16px;
}

.footer-column-item__link{
    margin-bottom: 10px;
    font-size: 14px;
    display: block;
    transition: 400ms;
    &:hover{
        opacity: 0.7;
    }
}

.footer-container__terms{
    font-size: 10px;
    a{
        &:hover{
            text-decoration: underline;
        }
    }
}