body{
    background-color: #f5f5f5;
}
.integrations-section{
    margin-bottom: 80px;
    .container{
        @include media (get-bp(desktop)){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.integrations-section__text{
    @include media (get-bp(desktop)){
        width: 30%;
    }
}

.integrations-section__title{
    color: get-color(brown);
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
    @include media (get-bp(desktop)){
        font-size: 22px;
    }
}

.integrations-section__subtitle{
    font-size: 14px;
    font-weight: 300;
    @include media (get-bp(desktop)){
        font-size: 18px;
    }
}

.integrations-section__squares{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 60px;
    @include media (get-bp(desktop)){
        width: 70%;
        margin-top: 0;
        justify-content:flex-end;
    }
    .square-item{
        background-color:#fff;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
        border-radius: 6px;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 40%;
        height: 140px;
        margin-bottom: 20px;
        margin-left: 20px;
        &:nth-child(2n){
            margin-top: -60px;
        }
        @include media (get-bp(desktop)){
            width: 160px;
            height: 160px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

