.lojista-cta-banner{
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 0.2rem 0.7rem rgba(#000, 0.3);
    margin-bottom: 50px;
    @include media (get-bp(desktop)){
        display: flex;
        align-items: center;
        margin-bottom: 100px;
    }
}

.lojista-cta-banner__image{
    width: 100%;
    @include media (get-bp(desktop)){
        width: 45%;
    }
    img{
        width: 100%;
        margin-bottom: -7px;
    }
}

.lojista-cta-banner__text{
    padding: 20px;
    @include media (get-bp(desktop)){
        width: 55%;
        padding-left: 50px;
    }
}

.lojista-cta-banner__title{
    font-weight: bold;
    color: #000;
    font-size: 24px;
    margin-bottom: 20px;
    @include media (get-bp(desktop)){
        font-size: 32px;
    }
}

.lojista-cta-banner__subtitle{
    font-size: 18px;
    font-weight: 300;
    color: #717171;
    margin-bottom: 40px;
}

.lojista-cta-banner__button{
    background-color: get-color(secondary);
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 4px;
    display: inline-flex;
    transition: 400ms;
    &:hover{
        opacity: 0.7;
    }
}