.cookie-bar-container{
    background-color: #FFF1DA;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    position: fixed;
    bottom:0;
    left: 0;
    z-index: 20;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    transition: 500ms;
    transform: translateY(100%);
    &.active{
        transform: translateY(0);
    }
    .container{
        @include media (get-bp(desktop)){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.cookie-bar__text{
    font-size: 14px;
    margin-bottom: 20px;
    @include media (get-bp(desktop)){
        margin-bottom: 0;
        width: 80%;
    }

    p{
        margin: 0;
    }

    a{
        text-decoration: underline;
        color: get-color(primary);
    }
}

.cookie-bar__button{
    background-color: get-color(primary);
    padding: 10px 20px;
    border-radius: 4px;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    transition: 400ms;
    text-align: center;
    &:hover{
        opacity: 0.6;
    }
}

.logaroo-right{
    width: 100%;
    display: flex;
    @media (max-width: 1100px){
        > div{
            width: 100% !important;
            iframe{
                object-fit: contain;
                width: 100% !important;
            }
        }
    }
    @include media (get-bp(desktop)){
        justify-content: flex-end;
    }
}