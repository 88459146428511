.doubts-section{
    padding-bottom: 50px;
    margin-bottom: 50px;
}

.doubts-section__title{
    text-align: center;
    color: get-color(brown);
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 40px;
    span{
        color: red;
    }
}

.doubts-section__listing{
    @include media (get-bp(desktop)){
        width: 80%;
        margin: auto;
    }

    .accordion{
        border: 0;
        margin-bottom: 10px;
    }

    .accordion__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
        font-weight: 300;
        &.is-active{
            .accordion__icon{
                transform: rotate(180deg);
            }
        }
    }

    .accordion__content{
        margin-top: 20px;
    }

    .accordion__icon{
        color: get-color(primary);
        font-size: 22px;
        transition: 400ms;
    }

    .accordion__content{
        font-size: 14px;
        font-weight: 300;
    }
}