.signup-banner{
    background-color: get-color(brown);
    padding-top: 50px;
    padding-bottom: 50px;
    .container{
        @include media (get-bp(desktop)){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.signup-banner__text{
    color: #FFF1DA;
    margin-bottom: 40px;
    font-weight: 300;
    @include media (get-bp(desktop)){
        width: 48%;
        margin-bottom: 0;
    }
}

.signup-banner__image{
    @include media (get-bp(desktop)){
        width: 48%;
    }
}

.signup-banner__title{
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 30px;
    @include media (get-bp(desktop)){
        font-size: 42px;
    }
}

.signup-banner__subtitle{
    font-size: 18px;
    margin-bottom: 50px;
}

.signup-banner__link{
    transition: 400ms;
    &:hover{
        opacity: 0.5;
    }
}