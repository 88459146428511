.testimonials{
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 50px;
    background-color: #FFF1DA;
}

.testimonials__title-box{
    text-align: center;
    color: get-color(brown);
    font-weight: bold;
    font-size: 42px;
    margin-bottom: 30px;
}

.testimonials__slider{
    padding-bottom: 80px;
    .testimonial-item{
        background-color: #fff;
        border-radius: 12px;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .testimonial-item__text{
        width: 80%;
        margin: auto;
    }

    .testimonial-item__author-row{
        width: 80%;
        margin: auto;
        display: flex;
        margin-top: 30px;
        align-items: center;
    }

    .testimonial-item__photo{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 15px;
    }

    .testimonial-item__author{
        font-weight: bold;
        color: get-color(brown);
        text-transform: uppercase;
    }

    .testimonial-item__job{
        font-size: 14px;
        font-weight: 300;
    }

    .testimonial-item__text{
        font-size: 16px;
        font-weight: 300;
    }

    .testimonials-pagination{
        .swiper-pagination-bullet{
            width: 30px;
            height: 3px;
            border-radius: 1px;
        }

        .swiper-pagination-bullet-active{
            background-color: get-color(secondary);
        }
    }
}