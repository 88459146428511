.mercadoo-footer{
    background-color: #333;
    .container{
        display: flex;
        flex-direction:  column;
        align-items: center;
    }
}

.mercadoo-footer__logo{
    margin-top: -60px;
    margin-bottom: 30px;
}

.mercadoo-footer__menu{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    .menu-item{
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content:center;
        font-size: 20px;
        margin-left: 10px;
        margin-right: 10px;
        transition: 400ms;
        &:hover{
            transform: scale(1.2);
        }
    }
}

.mercadoo-footer__copyright{
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
}