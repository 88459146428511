.call-box{
    margin-bottom: 80px;
    @include media (get-bp(desktop)){
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        &:nth-child(2){
            flex-direction: row;
            .call-box__image{
                @include media (get-bp(desktop)){
                    margin-left: 0;
                    margin-right: -50px;
                }
            }
            .call-box__button{
                background-color: get-color(secondary);
            }
            .call-box__text-side{
                @include media (get-bp(desktop)){
                    margin-top: 0;
                }
            }
        }
    }
}

.call-box__text-side{
    padding: 30px;
    background-color: #fff;
    padding-top: 70px;
    border-radius: 12px;
    @include media (get-bp(desktop)){
        width: 45%;
        padding: 50px 30px;
        margin-top: -20px;
    }
}

.call-box__title{
    color: get-color(brown);
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    @include media (get-bp(desktop)){
        font-size: 28px;
    }
}

.call-box__subtitle{
    margin-bottom: 40px;
    font-size: 16px;
    @include media (get-bp(desktop)){
        font-size: 18px;
    }
}

.call-box__button{
    background-color: get-color(gold);
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    display: inline-flex;
    transition: 400ms;
    &:hover{
        opacity: 0.7;
    }
    i{
        margin-left: 10px;
        padding-top: 4px;
    }
}

.call-box__image{
    margin-bottom: -50px;
    @include media (get-bp(desktop)){
        margin-bottom: 0;
        flex: 1;
        margin-left: -50px;
    }
}