.form-section{
    margin-bottom: 100px;
    .container{
        @include media (get-bp(desktop)){
            display: flex;
            align-items: center;
        }
    }

    .logaroo-input-item{
        width: 100%;
        margin-bottom: 20px;
    }

    .logaroo-input-item__error{
        font-size: 8px;
        color: #ff0000;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 2px;
        margin-top: 5px;
        display: none;
    }

    input, textarea{
        background: #F8F8F8;
        border: 1px solid #ECECEC;
        box-sizing: border-box;
        border-radius: 8px;
        width: 100%;
        padding: 10px;
        font-size: 14px;
        outline: none;
        position: relative;
        &:focus{
            border-color: get-color(gold);
        }
        &.error{
            border-color: #ff0000;
            &::after{
                display: block;
            }
        }
    }

    textarea{
        height: 100px;
        resize: none;
    }

    input[type="submit"]{
        background-color: get-color(secondary);
        color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        text-align: center;
        width: 150px;
        transition: 400ms;
        cursor: not-allowed;
        &:hover{
            opacity: 0.7;
        }
        
        &.disabled{
            background-color: #ccc;
        }
    }

    .submit-container{
        margin-top: 50px;
        @include media (get-bp(desktop)){
            display: flex;
            justify-content: flex-end;
            position: relative;
            align-items: center;
        }
    }

    .submit-container__success{
        border: 1px solid #00dd00;
        border-left: 6px solid #00bb00;
        padding: 10px;
        display: flex;
        font-size: 12px;
        margin-right: 30px;
        opacity: 0;
        transition: 400ms;
        display: none;
        margin-bottom: 20px;
        @include media (get-bp(desktop)){
            margin-bottom: 0;
            position: absolute;
            top: 0;
            left: 0;
        }
        .success-icon{
            margin-right: 10px;
            color: #00dd00;
        }
        &.shown{
            display: flex;
        }
        &.opacity{
            opacity: 1;
        }
    }
}

.form-section__image{
    @include media (get-bp(desktop)){
        width: 50%;
    }
}

.form-section__form-box{
    background-color: #fff;
    border-radius: 6px;
    padding: 20px;
    position: relative;
    z-index: 2;
    width: 95%;
    margin: auto;
    margin-top: -50px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    @include media (get-bp(desktop)){
        width: 60%;
        padding: 40px;
        margin-top: 0;
        margin-left: -50px;
    }

    .input-row{
        @include media (get-bp(desktop)){
            display: flex;
            justify-content: space-between;
            .logaroo-input-item{
                width: 48%;
            }
        }
    }
}

.form-section__title{
    color: get-color(brown);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    @include media (get-bp(desktop)){
        font-size: 28px;
    }
}

.form-section__subtitle{
    font-size: 14px;
    margin-bottom: 20px;
}