.mercadoo-main-banner{
    background-color: #fff;
    overflow: hidden;
    padding-top: 30px;
    .container{
        @include media (get-bp(desktop)){
            padding-top: 70px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.mercadoo-main-banner__text{
    color: #333;
    @include media (get-bp(desktop)){
        width: 50%;
    }
}

.mercadoo-main-banner__image{
    @include media (get-bp(desktop)){
        width: 45%;
    }

    img{
        margin-bottom: -40px;
    }
}

.mercadoo-main-banner__title{
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 20px;
    font-size: 22px;
    @media (min-width: 768px){
        font-size: 32px;
    }
    @include media (get-bp(desktop)){
        font-size: 44px;
    }
}

.mercadoo-main-banner_subtitle{
    line-height: 1.2;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 30px;
    max-width: 450px;
    @media (min-width: 768px){
        font-size: 22px;
    }
    @include media (get-bp(desktop)){
        font-size: 18px;
    }
}

.mercadoo-main-banner__place{
    background-color: rgba(#FEC44A, 0.2);
    color: #333;
    padding: 10px;
    border-radius: 4px;
    display: inline-flex;
    margin-bottom: 30px;
    i{
        margin-right: 10px;
    }
}

.mercadoo-main-banner__buttons-row{
    @include media (get-bp(desktop)){
        display: flex;
        align-items: center;
    }
}

.mercadoo-main-banner__button{
    background-color: get-color(secondary);
    border: 2px solid get-color(secondary);
    margin-bottom: 20px;
    color: #fff;
    transition: 400ms;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    text-transform: uppercase;
    margin-right: 20px;
    flex: 1;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    &:hover{
        background-color: #fff;
        color: get-color(secondary);
    }
    @media (min-width: 768px){
        width: 300px;
    }
    @include media (get-bp(desktop)){
        display: block;
        width: 260px;
    }
}