.mercadoo-benefits{
    background-color: #333;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 50px;
}

.mercadoo-benefits__desktop-content{
    display: none;
    @include media (get-bp(desktop)){
        display: block;
    }
    .container{
        @include media (get-bp(desktop)){
            display: flex;
            align-items: center;
        }
    }
}

.mercadoo-benefits__mobile-content{
    display: block;
    @include media (get-bp(desktop)){
        display: none;
    }
}

.mercadoo-benefits__image{
    @include media (get-bp(desktop)){
        width: 50%;
        padding-top: 40px;
    }
}


.mercadoo-benefits__title{
    color: #FFF1DA;
    font-size: 22px;
    margin-bottom: 30px;
    @include media (get-bp(desktop)){
        font-size: 32px;
        margin-bottom: 40px;
    }
}

.mercadoo-benefits__text{
    @include media (get-bp(desktop)){
        width: 55%;
    }
}

.mercadoo-benefits__listing{
    @include media (get-bp(desktop)){
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .benefits-item{
        width: 46%;
        margin-bottom: 50px;
    }
}

.benefits-item__icon{
    font-size: 42px;
}

.benefits-item__title{
    color: get-color(secondary);
    font-size: 28px;
    margin-bottom: 10px;
}

.benefits-item__subtitle{
    color: #FFF1DA;
    font-size: 16px;
}

.mercadoo-benefits__mobile-title{
    color: #FFF1DA;
    font-size: 28px;
    margin-bottom: 50px;
}

.benefit-slide{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
}

.benefit-slide__image{
    width: 180px;
    margin: auto;
    margin-bottom: 20px;
}

.benefit-slide__icon{
    font-size: 42px;
    margin-bottom: 15px;
}

.benefit-slide__title{
    color: get-color(secondary);
    font-size: 18px;
    margin-bottom: 15px;
}

.benefit-slide__subtitle{
    color: #FFF1DA;
    font-size: 18px;
    width: 70%;
    font-weight: 300;
}

.mercadoo-benefits__slider{
    .swiper-pagination{
        bottom: 70px;
    }
    .swiper-pagination-bullet{
        width: 40px;
        height: 2px;
        background-color: #ccc;
    } 
    .swiper-pagination-bullet-active{
        background-color: get-color(secondary);
    }

    .slider-arrows{
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 50;
        
        .slider-arrows__item{
            background-color: get-color(secondary);
            color: #fff;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
}