.partners-section__title-box{
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    color: get-color(brown);
    margin-bottom: 70px;
}

.partners-section__icon{
    margin-bottom: 10px;
}

.partners-section__title{
    position: relative;
    &::after{
        content: '';
        width: 100px;
        height: 3px;
        background-color: get-color(gold);
        position: absolute;
        top: 120%;
        left: calc(50% - 50px);
    }
}

.partners-slider{
    @include media (get-bp(desktop)){
        display: none;
    }
    .partner-slide-item{
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        img{
            object-fit: contain;
        }
    }

    .swiper-pagination-bullet{
        width: 10px;
        height: 2px;
    }

    .swiper-pagination-bullet-active{
        background: get-color(primary);
    }
}

.partners-section__listing{
    display: none;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 1000px;
    margin: auto;
    margin-bottom: 50px;
    @include media (get-bp(desktop)){
        display: flex;
    }
    .partner-item{
        margin-bottom: 20px;
        margin-right: 20px;
        margin-left: 20px;
        @include media (get-bp(desktop)){
            width: 15%;
        }
    }
}