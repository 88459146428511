.collect-places{
    text-align: center;
    padding-bottom: 30px;
    @include media (get-bp(desktop)){
        padding-top: 10px;
        padding-bottom: 70px;
    }
}

.collect-places__icon{
    font-size: 42px;
    font-weight: bold;
}

.collect-places__title{
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1.2;
    @include media (get-bp(desktop)){
        font-size: 32px;
    }
}

.collect-places__subtitle{
    margin-bottom: 20px;
    font-size: 14px;
    @include media (get-bp(desktop)){
        width: 550px;
        margin: auto;
        font-size: 20px;
        margin-bottom: 50px;
    }
}

.collect-places__map{
    width: 100%;
    height: 300px;
    margin-bottom: 30px;
    @include media (get-bp(desktop)){
        width: 650px;
        height: 400px;
        margin: auto;
        margin-bottom: 40px;
    }
    iframe{
        width: 100%;
        height: 100%;
    }
}

.collect-places__disclaimer{
    a{
        color: get-color(primary);
        &:hover{
            text-decoration: underline;
        }
    }
}