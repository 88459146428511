.delivery-how-works{
    background-color: #FFF1DA;
    padding-top: 50px;
    padding-bottom: 50px;
    @include media (get-bp(desktop)){
        padding-top: 70px;
        padding-bottom: 70px;
    }
}

.delivery-how-works__title{
    color: get-color(brown);
    font-size: 32px;
    font-weight: bold;
    @include media (get-bp(desktop)){
        font-size: 42px;
    }
}

.delivery-how-works__subtitle{
    font-weight: 300;
    margin-bottom: 60px;
}

.delivery-how-works__desktop-content{
    display: none;
    @include media (get-bp(desktop)){
        display: block;
    }
}

.delivery-how-works__mobile-content{
    display: block;
    @include media (get-bp(desktop)){
        display: none;
    }
}

.delivery-how-works__listing{
    display: flex;
    align-items:flex-start;
    justify-content: space-between;
}

.delivery-how-works-item{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
    @include media (get-bp(desktop)){
        width: 22%;
    }
}

.delivery-how-works-item__number{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: get-color(gold);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    margin-bottom: 20px;
    color: get-color(brown);
}

.delivery-how-works-item__image{
    width: 130px;
    margin-bottom: 20px;
}

.delivery-how-works-item__title{
    font-weight: bold;
    margin-bottom: 10px;
}

.delivery-how-works-item__subtitle{
    font-size: 14px;
    font-weight: 300;
}

.delivery-mobile-pagination{
    .swiper-pagination-bullet{
        width: 30px;
        height: 2px;
        border-radius: 2px;
    }

    .swiper-pagination-bullet-active{
        background-color: get-color(brown);
    }
}