.bottom-cta{
    margin-bottom: 100px;
    .container{
        @include media (get-bp(desktop)){
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
    }
}

.bottom-cta__item{
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 40px;
    @media (min-width: 768px){
        display: flex;
        height: 300px;
    }
    @include media (get-bp(desktop)){
        display: block;
        width: 32%;
        height: unset;
    }
    &:nth-child(1){
        .bottom-cta__text-box{
            background-color: get-color(secondary);
        }
    }
    &:nth-child(2){
        .bottom-cta__text-box{
            background-color: get-color(gold);
        }
    }
    &:nth-child(3){
        .bottom-cta__text-box{
            background-color: get-color(brown);
        }
    }
}

.bottom-cta__image{
    height: 250px;
    overflow: hidden;
    width: 100%;
    @media (min-width: 768px){
        width: 45%;
        height: 100%;
    }
    @include media (get-bp(desktop)){
        width: 100%;
        height: 250px;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-bottom: -6px;
        @include media (get-bp(desktop)){
            object-position: top;
        }
    }
}

.bottom-cta__text-box{
    padding: 40px;
    color: #fff;
    @media (min-width: 768px){
        width: 55%;
    }
    @include media (get-bp(desktop)){
        width: 100%;
    }
}

.bottom-cta__title{
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 20px;
}

.bottom-cta__subtitle{
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 30px;
    @include media (get-bp(desktop)){
        height: 100px;
    }
}

.bottom-cta__link{
    border: 1px solid #fff;
    padding: 10px 20px;
    border-radius: 4px;
    transition: 400ms;
    &:hover{
        background-color: #fff;
        color: #333;
    }
    i{
        margin-left: 10px;
    }
}