.lojista-integrations{
    background-color: #f0f0f0;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
}

.lojista-integrations__icon{
    font-size: 42px;
    margin-bottom: 10px;
}

.lojista-integrations__title{
    color: get-color(brown);
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 60px;
    @include media (get-bp(desktop)){
        font-size: 26px;
    }
}

.lojista-integrations__listing{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include media (get-bp(desktop)){
        justify-content: center;
    }
    .integrations-item{
        margin-bottom: 20px;
        box-shadow: 0 0.2rem 0.7rem rgba(#000, 0.2);
        width: 48%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        background-color: #fff;
        border-radius: 12px;
        @include media (get-bp(desktop)){
            width: 15%;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
}