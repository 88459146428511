.float-button {
    position: fixed;
    bottom: 10px;
    right: 10px;
    a, button {
        cursor: pointer;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: get-color(primary);
        border: none;
        box-shadow: 0 1px 5px rgba(0,0,0,.4);
        font-size: 24px;
        color: get-color(light);
        -webkit-transition: .2s ease-out;
        -moz-transition: .2s ease-out;
        transition: .2s ease-out; 
    }
    a {
        display: flex;
        i {
            margin: 0 auto;
            align-self: center;
        }
    }
    a:hover {
        filter: brightness(1.2);
    }
    .float-button__main:hover {
        filter: none;
    }
    .float-button__main {
        position: absolute;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        background-color: get-color(header-blue);
        right: 0;
        bottom: 0;
        z-index: 5;
        transition: 400ms;
        &::before {
            font-family: FontAwesome;
            content: "\f013";
            color: get-color(light);
        }
    }
    ul {
        position: absolute;
        bottom: 0;
        right: 0;
        padding-right: 5px;
        margin: 0;
        list-style: none;
        z-index: 2;
        transition: 400ms;
    }
    li {
        display: flex;
        justify-content: flex-start;
        position: relative;
        bottom: -10%;
        opacity: 0;
        transition: 400ms;
        div {
            margin-right: 10px;
            white-space: nowrap;
            display: block;
            margin-top: 10px;
            padding: 5px 8px;
            background-color: get-color(header-blue);
            color: get-color(light);
            box-shadow: 0 1px 3px rgba($color: #000000, $alpha: 0.2);
            border-radius: 3px;
            height: 30px;
            font-size: 16px;
            pointer-events: none;
            transition: 400ms;
        }
    }
    .float-button__main:active, .float-button__main:focus {
        outline: none;
        background-color: get-color(primary);
        box-shadow: 0 3px 8px rgba(0,0,0,.5);
        &::before {
            content: "\f281";
        }
    }
    .float-button__main:active + ul, .float-button__main:focus + ul{
        bottom: 70px;
    } 
    .float-button__main:active + ul li, .float-button__main:focus + ul li {
        margin-bottom: 10px;
        opacity: 1;
    }
    .float-button__main:active + ul li:hover label, .float-button__main:focus + ul li:hover label{
        opacity: 1;
    }
}