.success-cases{
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 50px;
    background-color: #FFF1DA;
}

.success-cases__title-box{
    text-align: center;
    color: get-color(brown);
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 30px;
    line-height: 1.2;
    .success-cases-title__icon{
        font-size: 42px;
        margin-bottom: 20px;
    }
}

.success-cases__slider{
    padding-bottom: 80px;
    .success-case-item{
        background-color: #fff;
        border-radius: 12px;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .success-case-item__text{
        width: 80%;
        margin: auto;
    }

    .success-case-item__author-row{
        width: 80%;
        margin: auto;
        display: flex;
        margin-top: 30px;
        align-items: center;
    }

    .success-case-item__photo{
        width: 60px;
        height: 60px;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 15px;
        img{
            width: 100%;
            height: 100%;
        }
    }

    .success-case-item__author{
        font-weight: bold;
        color: get-color(brown);
        text-transform: uppercase;
    }

    .success-case-item__job{
        font-size: 14px;
        font-weight: 300;
    }

    .success-case-item__text{
        font-size: 16px;
        font-weight: 300;
    }

    .success-cases-pagination{
        .swiper-pagination-bullet{
            width: 30px;
            height: 3px;
            border-radius: 1px;
        }

        .swiper-pagination-bullet-active{
            background-color: get-color(secondary);
        }
    }
}