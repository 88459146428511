.deliveries-section{
    margin-bottom: 60px;
    background-color: #FFF1DA;
    padding-bottom: 30px;
    .container{
        @include media (get-bp(desktop)){
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
        }
    }
}

.deliveries-section__image{
    margin-bottom: 30px;
    @include media (get-bp(desktop)){
        width: 50%;
        margin-bottom: 0;
    }
}

.deliveries-section__text{
    @include media (get-bp(desktop)){
        width: 50%;
        padding-left: 100px;
    }
}

.deliveries-section__title{
    color: get-color(brown);
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 20px;
    font-size: 20px;
    @include media (get-bp(desktop)){
        font-size: 46px;
    }
}

.deliveries-section__subtitle{
    line-height: 1.2;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 30px;
    @include media (get-bp(desktop)){
        font-size: 18px;
    }
}

.deliveries-section__button{
    display: inline-flex;
    background-color: get-color(primary);
    color: #fff;
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;
    transition: 400ms;
    font-size: 14px;
    span{
        display:none;
        @include media (get-bp(desktop)){
            display: block;
        }
    }
    &:hover{
        opacity: 0.7;
    }
    i{
        margin-left: 10px;
        padding-top: 4px;
    }
}

.deliveries-section__store{
    display: block;
    margin-top: 30px;
    width: 180px;
}