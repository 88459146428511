.connect-section{
    padding-top: 60px;
    padding-bottom: 120px;
    background: linear-gradient(180deg, rgba(254,196,74,0.6) 0%, rgba(#f5f5f5,0.4) 100%);
    .container{
        @include media (get-bp(desktop)){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.connect-section__title{
    font-weight: bold;
    line-height: 1.4;
    color: get-color(brown);
    font-size: 26px;
    @include media (get-bp(desktop)){
        font-size: 40px;
        width: 50%;
        span{
            display: none;
        }
    }
}

.connect-section__icon{
    font-size: 72px;
    flex: 1;
    text-align: center;
    display: none;
    @include media (get-bp(desktop)){
        display: block;
    }
}

.connect-section__tags-box{
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    @include media (get-bp(desktop)){
        margin-top: 0;
        margin-bottom: 0;
    }    
}

.tag-box{
    &:not(:last-child){
        margin-right: 50px;
    }
}

.tag-box__title{
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 32px;
}

.tag-box__content{
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 200;
    line-height: 1.4;
}