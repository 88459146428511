.delivery-benefits{
    margin-bottom: 50px;
}

.delivery-benefits-item{
    padding-top: 80px;
    padding-bottom: 80px;
    &:nth-child(even){ 
        color: #FFF1DA;
        .container{
            flex-direction: row-reverse;
        }
    }
    &:nth-child(1){
        background-color: #FFF1DA;
    }
    &:nth-child(2){
        background-color: get-color(secondary);
    }
    &:nth-child(3){
        background-color: get-color(gold);
    }
    &:nth-child(4){
        background-color: get-color(brown);
    }
    @include media (get-bp(desktop)){
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.delivery-benefits-item__image{
    display: none;
    @include media (get-bp(desktop)){
        display: block;
        width: 48%;
    }
   
}

.delivery-benefits-item__title{
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    @include media (get-bp(desktop)){
        font-size: 32px;
    }
}

.delivery-benefits-item__subtitle{
    font-size: 16px;
    @include media (get-bp(desktop)){
        font-size: 22px;
    }
}

.delivery-benefits-item__text{
    @include media (get-bp(desktop)){
        width: 48%;
    }
}