.menu-container{
    color: get-color(light);
    flex: 1;
}

.menu-container__listing{
    display: flex;
    flex: 1;
}


.mobile-only-items{
    @include media (get-bp(desktop)){
        display: none;
    }
}