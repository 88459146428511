.how-works{
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: #fff;
}

.how-works__title{
    color: get-color(primary);
    font-weight: bold;
    font-size: 32px;
    text-align: center;
    @include media (get-bp(desktop)){
        margin-bottom: 70px;
    }
}

.how-works__desktop-content{
    display: none;
    @include media (get-bp(desktop)){
        display: block;
    }

    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.how-works__mobile-content{
    display: block;
    @include media (get-bp(desktop)){
        display: none;
    }
}

.how-works__listing{
    width: 50%;
}

.how-works-desktop{
    width: 40%;
    img{
        width: 100%;
    }
}


.how-works-item{
    position: relative;
    padding-bottom: 30px;
    &:not(:last-child){
        &::after{
            content: '';
            position: absolute;
            top: 30px;
            left: 14px;
            width: 2px;
            height: 100%;
            background-color: #ccc;
        }
    }
    &.is-active{
        
        .how-works-item__number{
            background-color: get-color(gold);
        }
        .how-works-item__text{
            color: get-color(secondary);
        }
    }
}

.how-works-item__title-row{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;

}

.how-works-item__number{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    position: relative;
    z-index: 2;
}

.how-works-item__text{
    font-size: 24px;
    transition: 400ms;
    span{
        margin-right: 15px;
    }
}

.how-works-item__subtitle{
    padding-left: 50px;
}

.how-works-mobile{
    padding-bottom: 70px;
    text-align: center;
}

.how-works-mobile__image{
    width: 70%;
    height: 250px;
    margin: auto;
    margin-bottom: 20px;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.how-works-mobile__number{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content:center;
    background-color: get-color(gold);
    border-radius: 50%;
    margin: auto;
    font-weight: bold;
    margin-bottom: 20px;
}

.how-works-mobile__title{
    font-weight: bold;
    color: get-color(secondary);
    font-size: 24px;
    margin-bottom: 20px;
    span{
        margin-right: 10px;
    }
}

.how-works-mobile__text{
    text-align: left;
    color: get-color(brown);
}

.how-works-mobile-pagination{
    .swiper-pagination-bullet{
        width: 35px;
        height: 6px;
        border-radius: 12px;
        background-color: rgba(get-color(primary), 0.8);
    }
}