.what-we-do{
    background-color: get-color(secondary);
    text-align: center;
    padding-top: 70px;
    padding-bottom: 70px;
}

.what-we-do__icon{
    font-size: 42px;
    margin-bottom: 20px;
}

.what-we-do__title{
    font-size: 26px;
    margin-bottom: 20px;
    font-weight: 500;
    color: #FFF1DA;
    @include media (get-bp(desktop)){
        font-size: 32px;
    }
}

.what-we-do__subtitle{
    color: #333;
    width: 80%;
    margin: auto;
    font-weight: 400;
    @include media (get-bp(desktop)){
        width: 70%;
    }
}